<template lang="html">
  <div id="results-page">

    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <span>Productos</span>
      </div>
    </section>

    <section class="container oversized-container products-section">
      <div class="row mb-3">
        <div class="col-12 col-s-title">
          <h2 class="title">Busqueda: <small>{{ $route.query.keywords }}</small></h2>
        </div>
      </div>

      <div class="row align-items-start">

        <!-- Mobile filters -->
        <!-- <div class="col-12 col-filters-mobile">
          <div class="bg-filter-mv" v-if="showFilters == true" @click="showFilters = false"></div>
          <a class="btn-filters" @click="showFilters = !showFilters">
            <i class="fas fa-filter"></i><span>Ver filtros</span>
          </a>
        </div> -->
        <!--  -->

        <!-- Col filters -->
        <!-- <div class="col-lg col-filters" v-bind:class="{ 'show-filters': showFilters == true }">
          <h4 class="_title">
            <span>Filtros de Búsqueda</span>
            <a class="btn-hide" @click="showFilters = false"><i class="fas fa-chevron-left"></i></a>
          </h4>

          <b-form class="filters-container" @submit="onSubmitFilters">

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Color</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="filtersForm.colors"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2">
                  <b-form-checkbox value="blanco">Blanco</b-form-checkbox>
                  <b-form-checkbox value="Negro">Negro</b-form-checkbox>
                  <b-form-checkbox value="Café">Café</b-form-checkbox>
                  <b-form-checkbox value="grape">Grape</b-form-checkbox>
                  <b-form-checkbox value="Beige">Beige</b-form-checkbox>
                  <b-form-checkbox value="Gris Claro">Gris Claro</b-form-checkbox>
                  <b-form-checkbox value="Gris Oscuro">Gris Oscuro</b-form-checkbox>
                  <b-form-checkbox value="Multicolor">Multicolor</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Tipo</a>

              <div class="box-filters-group">
                <b-form-checkbox
                  v-model="filtersForm.madera"
                  name="checkbox-11"
                  value="1" unchecked-value="">
                  Tipo 1
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="filtersForm.ceramica"
                  name="checkbox-12"
                  value="1" unchecked-value="">
                  Tipo 2
                </b-form-checkbox>
              </div>
            </div>

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Tamaño</a>

              <b-form-group class="box-filters-group" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  v-model="filtersForm.sizes"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-2">
                  <b-form-checkbox value="Chico">Chico</b-form-checkbox>
                  <b-form-checkbox value="Mediano">Mediano</b-form-checkbox>
                  <b-form-checkbox value="Grande">Grande</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>

            <div class="box-filters">
              <a class="btn-action"><i class="sy">-</i> Precio</a>

              <div class="box-filters-group">
                <b-form-select class="c-filter-select" v-model="filtersForm.precio">
                  <b-form-select-option :value="null">Seleccione un precio</b-form-select-option>
                  <b-form-select-option value="a">$800 o menos</b-form-select-option>
                  <b-form-select-option value="b">$1,499 o menos</b-form-select-option>
                  <b-form-select-option value="c">$1,500 o más</b-form-select-option>
                </b-form-select>
              </div>
            </div>

          </b-form>
        </div> -->
        <!--  -->

        <!-- Resultados en columnas -->
        <div class="col-lg">
          <div class="row sample-products-wrapper xxl-4e" v-if="!isLoading">
            <div class="col-sm-6 col-md-6 col-xl-4 sample-product shad-prod" v-for="(product, index) in products" :key="index">
              <router-link class="outlined-box" :to="'/producto/'+ $root._converToURL(product.name, product.id)">
                <div class="box-image">
                  <div class="note" v-if="product.isNew">Nuevo</div>
                  <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                  <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                    <img src="public/images/shared/empty.png" >
                  </div>
                </div>

                <div class="box-shot-descr">
                  <h6 class="brand">{{ product.sku }}</h6>
                  <h6 class="name">{{ product.name }}</h6>
                  <!-- <h6 class="price discount"><span>$ {{ product.price }}</span></h6> -->
                  <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                </div>
                <p class="p-button">
                  <a class="btn-more">Ver más</a>
                </p>
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center" v-if="!products.length && !isLoading">
              <h5>No se encontraron resultados</h5>
            </div>

            <div class="col-12" v-if="isLoading">
              <loading-component></loading-component>
            </div>
          </div>
        </div>
        <!--  -->

      </div>
    </section>

  </div>
</template>

<script>
import LoadingComponent from '../shared/loading-component.vue';
export default {
  data(){
    return{
      isLoading: true,
      showFilters: false,

      filtersForm: {
        precio: null,
        order: 0
      },
      filters:{
        keywords:null,
        categories_id:null,
      },
      products: [],
    }
  },

  methods: {
    onSubmitFilters(evt) {
      evt.preventDefault();
    },

    getSearch(){
      this.isLoading = true;
      axios.post(tools.url('/api/search'),this.filters).then((response)=>{
        this.products = response.data;
        this.isLoading = false;
      }).catch((error)=>{
        console.log(error);
      });
    },
  },

  watch:{
    '$route.query.keywords':function(){
      this.filters.keywords = this.$route.query.keywords;
      this.filters.categories_id = this.$route.query.category;
      this.isLoading = true;
      this.getSearch();
    },
    '$route.query.category':function(){
      this.filters.keywords = this.$route.query.keywords;
      this.filters.categories_id = this.$route.query.category;
      this.isLoading = true;
      this.getSearch();
    }
  },

  mounted(){
    setTimeout( ()=>{ this.isLoading = false; }, 2000); // ejemplo de "loading" despues de cargar

    if(this.$route.query.keywords){
      this.filters.keywords = this.$route.query.keywords;
      this.filters.categories_id = this.$route.query.category;
      this.getSearch();
    }
  },

  components: {
    LoadingComponent
  },
}
</script>
