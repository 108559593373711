<template lang="html">
  <div id="about-us-page-v2">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-lg-5 col-image">
          <img :src="image">
        </div>

        <div class="col-lg-7 col-info">
          <div class="col-s-title">
            <h2 class="title">Nosotros</h2>
          </div>
          <div v-html="content"></div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      content:null,
      image: null,
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/about')).then((response)=>{
        this.content = response.data.body;
        this.image = response.data.image;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    this.getContent();
  }
}
</script>
