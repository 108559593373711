<template lang="html">
  <div class="placed-backg faqs-page" id="page-info-t1">
    <div class="bg-image"></div>

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center" v-bind:class="{ 'tabla-page' : $route.params.table == 'tabla' }">
        <!-- <div class="col-lg-6 col-image">
        </div> -->

        <div class="col-lg-10 col-info" id="faqs-box">
          <h1 class="mb-4 page-title">Preguntas frecuentes</h1>
          <div class="box-qa"  v-html="content">
            
            
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/faq')).then((response)=>{
        this.content = response.data.body;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    this.getContent();
  }
}
</script>
