<template>
	
	<div class="row">
	
		<!-- Profile Info and Notifications -->
		<div class="col-md-6 col-sm-8 clearfix">
	
			<ul class="user-info pull-left pull-none-xsm">
	
				<!-- Profile Info -->
				<li class="profile-info dropdown"><!-- add class "pull-right" if you want to place this from right -->
					<a href="#" class="dropdown-toggle" data-toggle="dropdown">
						<img :src="profile()" alt="" class="img-circle" width="44" />
						{{this.$parent.user.name}}
					</a>
					<ul class="dropdown-menu">
						<!-- Reverse Caret -->
						<li class="caret"></li>
						<!-- Profile sub-links -->
						<li><router-link to="/profile/"><i class="fa fa-edit"></i> Editar perfil</router-link></li>
					</ul>
				</li>
	
			</ul>
	
		</div>
	
	
		<!-- Raw Links -->
		<div class="col-md-6 col-sm-4 clearfix hidden-xs">
			<ul class="list-inline links-list pull-right">	
				<li><button class="btn btn-info btn-sm" @click="priceCheck"><i class="fa fa-tags"></i></button></li>
				<li style="width:100px"></li>
				<li><a @click="logout">Cerrar session <i class="entypo-logout right"></i></a></li>
			</ul>
		</div>
		<sweet-modal ref="pricecheck" width="80%">

			<form role="form" class="form-horizontal" @submit.prevent="getProductsOpcs($event.target)">
				<p style="text-align:center;">Buscar producto</p>

				<div class="form-group">
					
					<div class="col-sm-11">
							
						<input class="form-control" type="search" name="searchProduct" v-model="searchProduct" :data-sync="searchProduct">
					</div>
					<div class="col-sm-1">
						<button class="btn btn-info btn-sm" type="submit"><i class="fa fa-search"></i></button>
					</div>
				</div>

			</form>
			<hr>

			<div v-show="loading == true">
				<img width="20%" src="/public/images/loading.gif">
			</div>
			<div v-show="loading == false">
				<table id="ProductsOrder"></table>
			</div>
			
		</sweet-modal>
	
	</div>
	
</template>

<script type="text/javascript">
	export default {
		data(){
			return {
				searchProduct:'',
				loading:false
			}	
		},
		
		methods:{
			profile(){
				return ('https://ui-avatars.com/api/?name='+this.$parent.user.name+'&size=256');
			},

			logout(){
				axios.post(tools.url("/logout")).then((response)=>{
			    	this.$parent.user = {};
			    	this.$parent.logged=false;
			    	this.$router.push('/login');
			    }).catch(()=>{});
			},	
			priceCheck(){
				this.$refs.pricecheck.open()
			},
			getProductsOpcs()
			{
				this.loading = true;
				this.$parent.inPetition=true;
		      	axios.post(tools.url("/api/admin/productsSearchPrice"),{keywords:this.searchProduct}).then((response)=>{
		          
		       		jQuery('#ProductsOrder').bootstrapTable('removeAll');
	          		jQuery('#ProductsOrder').bootstrapTable('append',response.data);
	          		this.loading = false;
		          this.$parent.inPetition=false;
		        }).catch((error)=>{
		            this.$parent.handleErrors(error);
		            this.$parent.inPetition=false;
		            this.loading = false;
		        });
			},
			mounthTable(){
		      	jQuery('#ProductsOrder').bootstrapTable({
			        columns: [
			          
			          {
			            field: 'image',
			            title: 'Imagen',
			            sortable:false,
			            switchable:true,
			          },  
			          {
			            field: 'category',
			            title: 'Tipo',
			            sortable:true,
			            switchable:true,
			          }, 
			          
			          {
			            field: 'sku',
			            title: 'SKU',
			            sortable:true,
			            switchable:true,
			          },
			          {
			            field: 'bin',
			            title: 'BIN #',
			            sortable:true,
			            switchable:true,
			          },
			          {
			            field: 'name',
			            title: 'Nombre',
			            sortable:true,
			            switchable:true,
			          },
			         
			          {
			            field: 'price',
			            title: 'Precio',
			            sortable:true,
			            switchable:true,
			          },
			           {
			            field: 'pricetax',
			            title: 'Precio+Tax',
			            sortable:true,
			            switchable:true,
			          },
			          {
			            field: 'cost',
			            title: 'Costo',
			            sortable:true,
			            switchable:true,
			          },
			          {
			            field: 'note',
			            title: 'Notas',
			            sortable:true,
			            switchable:true,
			          },
			         

			          
			        ],
			        showRefresh:false,
			      });

			      jQuery('#ProductsOrder').on('click-row.bs.table',(e,row,data,$element)=>{
			           	
			      });

			 },
		},
		mounted(){
			this.mounthTable();
		}
    }
</script>