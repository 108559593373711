<template lang="html">
  <div id="home-page">

    <!-- Banners  -->
    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-for="(banner, index) in banners.pc" :key="index">
          <a class="placed-backg" :href="banner.url ?  banner.url : '#'" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide  v-for="(banner, index) in banners.movil" :key="index">
           <a class="placed-backg" :href="banner.url ?  banner.url : '#'" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
             <img src="public/images/pages/home/banner-m.png">
           </a>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>
    <!--  -->

    <!-- Marcas -->
    <section class="container oversized-container py-4 brands-section" v-if="brands.length">
      <div class="row">
        <div class="col-12 col-brands">
          <swiper class="swiper" :options="brandsOptions">
            <swiper-slide v-for="(brand, brhInx) in brands" :key="'brhInx-'+brhInx">
              <div class="box-brand">
                <router-link :to="'/marca/'+brand.id">
                  <div class="inside">
                    <img :src="brand.imageUrl">
                  </div>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Productos | Lo + visto -->
    <section class="container oversized-container mb-4 products-section" v-if="products.length">
      <div class="row">
        <div class="col-12 col-sm-title">
          <h2 class="title">Lo + visto</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                    <h6 class="price discount" v-if="product.discount"><span>{{ product.discount }}</span></h6>
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver más</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!--  -->

    <!-- Multibanners -->
    
    <!--  -->

    <!-- Productos | Populares -->
    <!-- <section class="container oversized-container mb-4 products-section">
      <div class="row">
        <div class="col-12 col-sm-title">
          <h2 class="title">Populares</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, index) in products_popular" :key="index">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/producto/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <div class="out-of-stock" v-if="product.stock < 1">Agotado</div>

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.imageUrl+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                 
                    <h6 class="price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver más</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section> -->
    <!--  -->

    <!-- Banner extra -->
    <!-- <section class="banners-s2-section">
      <router-link to="">
        <img class="img-des" :src="banner_bottom['pc'][0]['imageUrl']">
        <img class="img-mob" :src="banner_bottom['movil'][0]['imageUrl']">
      </router-link>
    </section> -->
    <!--  -->

    <!-- Blog -->
    <!-- <section class="container oversized-container pb-4 brands-section" v-if="blogs.length">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">Posts destacados</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-lg-4 col-xl-3 col-article-sample-1" v-for="(blog, baInx) in blogs" :key="'ba-'+baInx">
          <router-link class="box-article" :to="'/blog/'+$root._converToURL(blog.title, blog.id)">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.imageUrl+')' }">
              <img src="public/images/pages/blog/empty.png">
            </div>

            <div class="box-descr">
              <h5>{{ blog.title }}</h5>
              <div class="descr" v-html="blog.body"></div>
            </div>
          </router-link>
        </div>
      </div>
    </section> -->
    <!--  -->

    <!-- Modal de imagen con publicidad -->
    <!-- <b-modal ref="modal-promo" size="lg" modal-class="modal-home-promo" centered hide-footer title="">
      <img :src="modal">
    </b-modal> -->
    <!--  -->

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners:[],
      banner_middle:[],
      banner_bottom:{
          pc:[],
          movil:[]
      },
      brands: [],
      products:[],
  
      
    

      /* == Carousel options == */
      bannersOption: {
        loop: true,
        speed:800,
        effect: 'fade',

        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true
        },

        autoplay: {
          delay: 553500,
          disableOnInteraction: false
        },
      },

      brandsOptions: {
        loop: false,
        slidesPerView: 7,
        spaceBetween: 35,

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        breakpoints: {
          1300: {
            slidesPerView: 6,
          },
          1100: {
            slidesPerView: 5,
          },
          992: {
            slidesPerView: 4,
          },
          767: {
            slidesPerView: 3,
          },
          470: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }
      },

      productsOption: {
        loop: false,
        slidesPerView: 5,
        spaceBetween: 10,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1399: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 10,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },

      multibanersOptions: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1400: {
            slidesPerView: 4
          },
          992: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        },
      }
      // == ==
    }
  },

  methods: {
    getHome: function(){
        axios.get(tools.url('/api/home')).then(({data}) =>{
            this.banners = data.banners;
            this.products = data.products;
            /*
            this.banners = data.banners;
            this.banner_middle = data.banner_middle;
            this.banner_bottom = data.banner_bottom;
            this.brands = data.marcas;
            this.products_mostview = data.products_mostview;
            this.products_popular = data.products_popular;

            */
            /*this.products = data.destacados;
            this.banner1 = data.banner1;
            this.banner2 = data.banner2;
            this.banner3 = data.banner3;
            this.categories = data.categorias;
            
            this.blogs = data.blog;
            this.ofertas = data.ofertas;
            this.modal = data.modal;
            if(this.modal && this.$root.modal){
                this.$refs['modal-promo'].show();
                this.$root.modal = false;
            }*/
        });
    }
  },

  mounted(){
    this.getHome();
  }

}
</script>
