<template>
	<div class="row">
		<div class="col-md-offset-2 col-md-8">
			<div class="profile-env"> 
				<!-- <header class="row"> 
					<div class="col-sm-2"> <a class="profile-picture"> 
						<img :src="profile()" class="img-responsive img-circle" width="115px"> </a> 
					</div> 
					<div class="col-sm-7"> 
						<ul class="profile-info-sections"> 
							<li> <div class="profile-name"> <strong> <a>{{user.name}}</a> <a href="#" class="user-status is-online tooltip-primary" data-toggle="tooltip" data-placement="top" data-original-title="Online"></a> </strong> <span>{{roles}}</span> </div> </li> 
						</ul> 
					</div> 
					<div class="col-sm-3"> 
						
					</div> 
				</header>  -->
				<div>
					<p style="text-align: center;"><b>{{ rows.length }}</b> productos requieren ser ordenados</p>
					<table id="table"></table>
				</div>

				<!-- <section class="profile-feed">
					<div class="profile-stories">
						<b>Notificaciones:</b>
						<article class="story" v-for="(notification, index) in notifications" :key="index">
							<aside class="user-thumb">
								<router-link v-if="notification.url!=null" :to="notification.url">
									<img :src="'/img/avatar.png'" alt="" class="img-circle" width="44"> 
								</router-link> 
								<a v-else>
									<img :src="'/img/avatar.png'" alt="" class="img-circle" width="44"> 
								</a>
							</aside> 
							<div class="story-content"> 
								<header> 
									<div class="publisher"> 
										<router-link v-if="notification.url!=null" :to="notification.url">{{$parent.user.name}}</router-link>
										<a v-else>{{$parent.user.name}}</a>
										<em>{{notification.created_at}}</em> 
									</div> 
									<div class="story-type"> <i :class="notification.icon"></i> </div> 
								</header> 
								<div class="story-main-content"> <p>{{notification.text}} </p></div>
								<hr> 
							</div> 
						</article>
					</div>
				</section> -->
				
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				user:{
					name:"",
					email:"",
					img:{key:""},
					phone:"",
					celphone:"",
					roles:[]
				},
				rows:[],
				notifications:{},
			}
		},
		computed:{
			roles:function(){
				let roles=jQuery.map(this.user.roles,(row)=>{
					return row.name;
				});
				return roles.join(", ");
			}
		},
		methods:{
			profile(){
				return ('https://ui-avatars.com/api/?name='+this.$parent.user.name+'&size=256');
			},
			getInfo(){
				axios.get(tools.url("/api/admin/productsbackorder")).then((response)=>{
					this.rows = response.data;
					jQuery('#table').bootstrapTable('removeAll');
            		jQuery('#table').bootstrapTable('append',this.rows);
				}).catch((error)=>{

				});
			},
			mounthTable(){
				jQuery('#table').bootstrapTable({
				columns: [
					{
					field:"check",
					checkbox:true,
					align: 'center',
					},        
					
					{
						field: 'name',
						title: 'Name',
						sortable:true,
						switchable:true,
					}, 
					{
						field: 'in_stock_qty',
						title: 'In Stock Qty',
						sortable:true,
						switchable:true,
					},
					{
						field: 'reorder_qty',
						title: 'Reorder Qty',
						sortable:true,
						switchable:true,
					},
					{
						field: 'ordered',
						title: 'Ordered',
						sortable:true,
						switchable:true,
					},
					{
						field: 'ticketBtn',
						title: 'Tickect',
						sortable:true,
						switchable:true,
					},
					
							
				],
				showRefresh:true,
				
				});
		
				jQuery('#table').on('refresh.bs.table',()=>{
					this.getContent();
				});
		
				jQuery('#table').on('click-row.bs.table',(e,row,data,$element)=>{
					if($element == 'ticketBtn'){
						this.$router.push('/tickets/edit/'+row.tickets_id );
					}
					//this.$router.push('/quotes/edit/'+data.id);
				});
				this.getInfo();
			},
		},
        mounted() {
        	this.user = this.$parent.user;
			this.mounthTable();
        }
    }
</script>